@font-face {
    font-family: 'BR Segma';
    font-style: normal;
    font-weight: 400;
    src: local(''), url('https://fonts.cdnfonts.com/s/107550/BRSegma-Regular-BF654d990a3279a.woff') format('woff');
}
@font-face {
    font-family: 'BR Segma';
    font-style: normal;
    font-weight: 250;
    src: local(''), url('https://fonts.cdnfonts.com/s/107550/BRSegma-Thin-BF654d990a32597.woff') format('woff');
}
@font-face {
    font-family: 'BR Segma';
    font-style: normal;
    font-weight: 260;
    src: local(''), url('https://fonts.cdnfonts.com/s/107550/BRSegma-ExtraLight-BF654d9909e41a1.woff') format('woff');
}
@font-face {
    font-family: 'BR Segma';
    font-style: normal;
    font-weight: 300;
    src: local(''), url('https://fonts.cdnfonts.com/s/107550/BRSegma-Light-BF654d990a3c0f6.woff') format('woff');
}
@font-face {
    font-family: 'BR Segma';
    font-style: normal;
    font-weight: 500;
    src: local(''), url('https://fonts.cdnfonts.com/s/107550/BRSegma-Medium-BF654d9909e2529.woff') format('woff');
}
@font-face {
    font-family: 'BR Segma';
    font-style: normal;
    font-weight: 600;
    src: local(''), url('https://fonts.cdnfonts.com/s/107550/BRSegma-SemiBold-BF654d990a3d889.woff') format('woff');
}
@font-face {
    font-family: 'BR Segma';
    font-style: normal;
    font-weight: 700;
    src: local(''), url('https://fonts.cdnfonts.com/s/107550/BRSegma-Bold-BF654d9909ed277.woff') format('woff');
}
@font-face {
    font-family: 'BR Segma';
    font-style: normal;
    font-weight: 900;
    src: local(''), url('https://fonts.cdnfonts.com/s/107550/BRSegma-Black-BF654d99091e5a2.woff') format('woff');
}