@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("./assets/fonts.css");

@layer base {
	html {
		font-family: BR Segma, system-ui, sans-serif;
		background-color: #ffffff;
	}
}

* {
	font-family: "BR Segma", sans-serif;
}

body,
html {
	font-family: "BR Segma", sans-serif; /* Use BR Segma font */
}

.App {
	font-family: "BR Segma", sans-serif;
}
